import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import placeholderImage from '../img/placeholder-img.png';
import EditItemModal from './EditItemModal';
import AddItemModal from "./AddItemModal";

const ItemsTab = () => {
    const [error, setError] = useState(null);
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
    const [showSoldoutModal, setShowSoldoutModal] = useState(false);
    const [showMultipleSoldoutModal, setShowMultipleSoldoutModal] = useState(false);
    const [showMultipleAdjustPriceModal, setShowMultipleAdjustPriceModal] = useState(false);
    const [showSkippedItemsModal, setShowSkippedItemsModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToSoldout, setItemToSoldout] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [photoSrc, setPhotoSrc] = useState(placeholderImage);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedRequiredOptions, setSelectedRequiredOptions] = useState([]);
    const [newRequiredOptions, setNewRequiredOptions] = useState([]);
    const [newOptionalOptions, setNewOptionalOptions] = useState([]);
    const [selectedOptionalOptions, setSelectedOptionalOptions] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [requiredOptions, setRequiredOptions] = useState([]);
    const [optionalOptions, setOptionalOptions] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [price, setPrice] = useState('');
    const [categories, setCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const itemsPerPage = 50;
    const [searchQuery, setSearchQuery] = useState("");
    const searchResults = filteredItems.filter(item =>
        item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const totalPages = Math.ceil(searchResults.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const adjustedPage = currentPage > totalPages ? totalPages : currentPage;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);
    const [editingItem, setEditingItem] = useState({
        image: null,
        item_name: '',
        item_description: '',
        price: '',
        category_id: '',
        subcategory_id: "",
    });
    const [showEditModal, setShowEditModal] = useState(false);
    const [localImage, setLocalImage] = useState(
        typeof editingItem.image === 'string' && editingItem.image.includes('../')
            ? `https://lkofoods.com/${editingItem.image}`
            : editingItem.image || placeholderImage
    );
    const [selectedItems, setSelectedItems] = useState([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [adjustmentType, setAdjustmentType] = useState('amount');
    const [adjustmentDirection, setAdjustmentDirection] = useState('increase');
    const [adjustmentValue, setAdjustmentValue] = useState('');
    const [showPriceAdjustment, setShowPriceAdjustment] = useState(false);
    const [skippedItems, setSkippedItems] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [subcategoryId, setSubcategoryId] = useState('');
    const [updatedRequiredOptions, setUpdatedRequiredOptions] = useState([]);
    const [updatedOptionalOptions, setUpdatedOptionalOptions] = useState([]);
    const [removedOptions, setRemovedOptions] = useState([]);


    useEffect(() => {
    }, [filteredItems]);


    const fetchMenuItems = async (selectedFilter) => {
        setIsSubmitting(true);
        try {
            const response = await fetch("/php/fetch-instock-items.php");

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.status !== 'success' || !Array.isArray(responseData.data)) {
                throw new Error('Invalid response data');
            }

            // Set all menu items
            setMenuItems(responseData.data);

            // Filter items based on the selectedFilter
            if (selectedFilter && selectedFilter !== "all") {
                const selectedCategoryId = parseInt(selectedFilter);
                const filtered = responseData.data.filter(item => item.category_id === selectedCategoryId);
                setFilteredItems(filtered);
            } else {
                setFilteredItems(responseData.data);
            }

        } catch (error) {
            console.error("Error fetching menu items:", error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const fetchCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('/php/categories.php');
            const data = await response.json();
            setTimeout(() => {
                setCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };

    const fetchAllCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('/php/fetch-categories.php');
            const data = await response.json();
            setTimeout(() => {
                setAllCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };

    const fetchOptions = async () => {
        try {
            const response = await axios.get('/php/fetch-options.php');
            setRequiredOptions(response.data.required_options);
            setOptionalOptions(response.data.optional_options);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        fetchMenuItems(selectedFilter);
        fetchCategories();
        fetchOptions();
        fetchAllCategories();
    }, [selectedFilter]);

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const confirmMarkAsSoldout = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToSoldout || !itemToSoldout.id) {
            setErrorWithTimeout('Invalid item selected for marking as sold out');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`/php/soldout-items.php?id=${itemToSoldout.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ in_stock: 0 })
            });

            const data = await response.json();
            if (data.status === 'success') {
                showSuccess('Item marked as sold out');
            } else {
                setErrorWithTimeout('Error marking item as sold out');
            }
        } catch (error) {
            setErrorWithTimeout('Error marking item as sold out');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowSoldoutModal(false);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };

    const confirmMarkSelectedAsSoldout = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch("/php/multiple-soldout-items.php", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    items: selectedItems.map((id) => ({ id, in_stock: 0 })),
                }),
            });

            const result = await response.json();

            if (result.status === "success") {
                showSuccess(result.message);
            } else {
                setErrorWithTimeout(result.message);
            }

        } catch (error) {
            setErrorWithTimeout("An error occurred while marking items as sold out");
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowMultipleSoldoutModal(false);
                setSelectedItems([]);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };

    const handleFilterChange = (selectedCategory) => {
        setSelectedFilter(selectedCategory);
        setCurrentPage(1);
        if (selectedCategory === "all") {
            setFilteredItems(menuItems);
        } else {
            const selectedCategoryId = parseInt(selectedCategory);
            const filtered = menuItems.filter(item => item.category_id === selectedCategoryId);
            setFilteredItems(filtered);
        }
    };

    useEffect(() => {
    }, [selectedFilter]);

    const handleAddItemClick = () => {
        setShowAddItemModal(true);
        resetFormState();
        fetchOptions();
    };

    const handleCloseModal = () => {
        setShowAddItemModal(false);
        setShowDeleteModal(false);
        setShowSoldoutModal(false);
        setShowMultipleSoldoutModal(false);
        setShowMultipleDeleteModal(false);
        setShowMultipleAdjustPriceModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setItemName('');
        setItemDescription('');
        setPrice('');
        setCategoryId('');
        setSelectedRequiredOptions([]);
        setNewRequiredOptions([]);
        setSelectedOptionalOptions([]);
        setNewOptionalOptions([]);
        setRequiredOptions([]);
        setOptionalOptions([]);
        setPhoto(null);
        setPhotoSrc(placeholderImage);
    };


    const handleAddSubmit = async (e) => {
        e.preventDefault();
    
        // Validate required fields for menu item
        if (!itemName || !categoryId) {
            setErrorWithTimeout('Please fill in all required fields.');
            return;
        }
    
        setIsSubmitting(true);
    
        // Create a FormData object to handle both text data and the file
        const formData = new FormData();
        formData.append('_method', 'POST'); // Simulate POST request
        formData.append('item_name', itemName);
        formData.append('item_description', itemDescription);
        formData.append('price', price || 0.00);
        const selectedCategoryId = subcategoryId ? subcategoryId : categoryId;
        formData.append('category_id', selectedCategoryId);
    
        // Append selected required options (if any)
        formData.append('selected_required_options', JSON.stringify(selectedRequiredOptions || []));
    
        // Append selected optional options (if any)
        formData.append('selected_optional_options', JSON.stringify(selectedOptionalOptions || []));
    
        // Append new required options (if any)
        formData.append('new_required_options', JSON.stringify(newRequiredOptions || []));
    
        // Append new optional options (if any)
        formData.append('new_optional_options', JSON.stringify(newOptionalOptions || []));
    
        // Append the photo file if it's uploaded
        if (photo) {
            formData.append('photo', photo);
        }
    
        // Log formData entries for debugging
        console.log("Form Data being sent to backend:");
        for (const [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }
    
        try {
            await axios.post('/php/add-new-item.php', formData);
    
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                setSelectedFilter("all");
                fetchMenuItems(selectedFilter);
                showSuccess('Menu item added successfully');
            }, 500);
        } catch (error) {
            setErrorWithTimeout('Error adding menu item.');
            setIsSubmitting(false);
            console.error('Error adding menu item:', error);
        }
    };    

    const handleDeleteItem = (item) => {
        setItemToDelete(item);
        setShowDeleteModal(true);
    };

    const handleMarkAsSoldout = (item) => {
        setItemToSoldout(item);
        setShowSoldoutModal(true);
    };

    const confirmDeleteItem = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToDelete || !itemToDelete.id) {
            setErrorWithTimeout('Invalid item selected for deletion');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.delete('/php/add-new-item.php', {
                data: {
                    id: itemToDelete.id
                }
            });

            if (response.data.status === 'success') {
                showSuccess('Item deleted successfully');
            } else {
                setErrorWithTimeout('Error deleting item');
            }
        } catch (error) {
            setErrorWithTimeout('Error deleting item');
            console.error('Error deleting item:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };

    const confirmDeleteSelectedItems = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const promises = selectedItems.map((itemId) =>
                axios.delete('/php/add-new-item.php', {
                    data: { id: itemId }
                })
            );

            const responses = await Promise.all(promises);

            const successCount = responses.filter((response) => response.data.status === 'success').length;
            const failedCount = responses.length - successCount;

            if (successCount > 0) {
                showSuccess(`${successCount} item(s) deleted successfully`);
            }
            if (failedCount > 0) {
                setErrorWithTimeout(`${failedCount} item(s) failed to delete`);
            }

        } catch (error) {
            setErrorWithTimeout('An error occurred while deleting items');
            console.error('Error deleting items:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                setSelectedItems([]);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };


    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };



    // Handle selection of a required option
    const handleRequiredOptionSelect = (optionId) => {
        if (optionId) {
            const selectedOption = requiredOptions.find(option => option.id === optionId);
            if (selectedOption && !selectedRequiredOptions.some(option => option.id === selectedOption.id)) {
                setSelectedRequiredOptions(prevState => [
                    ...prevState,
                    selectedOption
                ]);
            }
        }
    };

    // Handle selection of an optional option
    const handleOptionalOptionSelect = (optionId) => {
        if (optionId) {
            const selectedOption = optionalOptions.find(option => option.id === optionId);
            if (selectedOption && !selectedOptionalOptions.some(option => option.id === selectedOption.id)) {
                setSelectedOptionalOptions(prevState => [
                    ...prevState,
                    selectedOption
                ]);
            }
        }
    };
    // Handle input changes for the new required option fields
    const handleNewOptionNameChange = (e, index) => {
        const updatedOptions = [...newRequiredOptions];
        updatedOptions[index].option_name = e.target.value;
        setNewRequiredOptions(updatedOptions);
    };

    const handleNewOptionPriceChange = (e, index) => {
        const updatedOptions = [...newRequiredOptions];
        updatedOptions[index].option_price = e.target.value;
        setNewRequiredOptions(updatedOptions);
    };
    // Handle removing a required option
    const handleRemoveRequiredOption = (index) => {
        const updatedOptions = selectedRequiredOptions.filter((_, i) => i !== index);
        setSelectedRequiredOptions(updatedOptions);
    };


    // Function to remove a newly added required option
    const handleRemoveNewRequiredOption = (index) => {
        const updatedOptions = newRequiredOptions.filter((_, i) => i !== index);
        setNewRequiredOptions(updatedOptions);
    };

    // Function to display a new required option input field when "Add Option" is clicked
    const handleAddNewRequiredOption = (e) => {
        e.preventDefault();
        setNewRequiredOptions(prevState => [
            ...prevState,
            { option_name: '', option_price: '', isNew: true, option_type: 'required' } // New entry with empty name, price, and type
        ]);
    };

    // Handle removing an optional option
    const handleRemoveOptionalOption = (index) => {
        const updatedOptions = selectedOptionalOptions.filter((_, i) => i !== index);
        setSelectedOptionalOptions(updatedOptions);
    };

    // Add a new input field for entering a new optional option
    const handleAddNewOptionalOption = (e) => {
        e.preventDefault();
        setNewOptionalOptions(prevState => [
            ...prevState,
            { option_name: '', option_price: '', isNew: true, option_type: 'optional' } // New entry with empty name, price, and type
        ]);
    };

    // Handle input changes for the new optional option fields
    const handleNewOptionalOptionNameChange = (e, index) => {
        const updatedOptions = [...newOptionalOptions];
        updatedOptions[index].option_name = e.target.value;
        setNewOptionalOptions(updatedOptions);
    };

    const handleNewOptionalOptionPriceChange = (e, index) => {
        const updatedOptions = [...newOptionalOptions];
        updatedOptions[index].option_price = e.target.value;
        setNewOptionalOptions(updatedOptions);
    };

    // Remove a new optional option input field
    const handleRemoveNewOptionalOption = (index) => {
        const updatedOptions = newOptionalOptions.filter((_, i) => i !== index);
        setNewOptionalOptions(updatedOptions);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle photo selection with size check
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB limit

        if (file) {
            // Check file size
            if (file.size > maxSize) {
                setErrorWithTimeout('File size exceeds the 5MB limit');
                e.target.value = ''; 
                return;
            }

            // Revoke previous photo URL if it exists and isn't the placeholder
            if (photoSrc && photoSrc !== placeholderImage) {
                URL.revokeObjectURL(photoSrc);
            }

            // Set the new photo source and file
            const newPhotoSrc = URL.createObjectURL(file);
            setPhotoSrc(newPhotoSrc);
            setPhoto(file);
        }
    };

    const handleRemovePhoto = () => {
        // Revoke the object URL if there is an uploaded image
        if (photoSrc && photoSrc !== placeholderImage) {
            URL.revokeObjectURL(photoSrc);
        }

        // Reset to placeholder image and clear the file input
        setPhoto(null);
        setPhotoSrc(placeholderImage);
        document.getElementById('fileInput').value = ''; // Clear file input field
    };


    const handleEditClick = (item) => {

        let parentCategory = null;
        categories.forEach((cat) => {
            if (cat.subcategories.some(sub => sub.id == item.category_id)) {
                parentCategory = cat;
            }
        });

        setEditingItem({
            ...item,
            category_id: parentCategory ? parentCategory.id : item.category_id,
            subcategory_id: parentCategory ? item.category_id : "",
        });

        setShowEditModal(true);
    };


    const handleEditSubmit = async (e) => {
        e.preventDefault();

        if (!editingItem.item_name.trim() || !editingItem.category_id) {
            setErrorWithTimeout('Please fill in all required fields.');
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('id', editingItem.id);
        formData.append('item_name', editingItem.item_name.trim());
        formData.append('item_description', editingItem.item_description || '');
        formData.append('price', editingItem.price || 0.00);
        formData.append('category_id', editingItem.subcategory_id || editingItem.category_id);

        // If no new photo is provided, just use the existing photo URL if it exists
        if (editingItem.image && typeof editingItem.image === 'string') {
            formData.append('photo_url', editingItem.image);
        } else {
            // Clear the photo URL if no image is selected
            formData.append('photo_url', ''); // Or use a placeholder URL if needed
        }

        // Prepare and log options data
        const optionsPayload = {
            selected: {
                required: updatedRequiredOptions.map(opt => ({
                    id: opt.id || null,
                    option_name: opt.option_name,
                    option_price: opt.option_price,
                    option_type: "required",
                })),
                optional: updatedOptionalOptions.map(opt => ({
                    id: opt.id || null,
                    option_name: opt.option_name,
                    option_price: opt.option_price,
                    option_type: "optional",
                }))
            },
            removed: removedOptions
        };

        formData.append('options_data', JSON.stringify(optionsPayload));

        try {
            const response = await axios.post('/php/edit-item.php', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                transformRequest: (data) => {
                    return data;
                }
            });
            setIsSubmitting(false);
            handleCloseEditModal();
            fetchMenuItems(selectedFilter);
            showSuccess('Menu item updated successfully');
        } catch (error) {
            console.error('Error updating menu item:', {
                error: error.message,
                response: error.response?.data,
                config: error.config
            });
            setErrorWithTimeout(error.response?.data?.message || 'Error updating menu item.');
            setIsSubmitting(false);
        }
    };

    const resetEditingItem = () => {
        setEditingItem({
            item_name: '',
            item_description: '',
            price: '',
            category_id: '',
            image: null
        });

        // Reset required and optional options
        setUpdatedRequiredOptions([]);
        setUpdatedOptionalOptions([]);

        // Reset removed options if needed
        setRemovedOptions([]);
    };


    const handleCloseEditModal = () => {
        setShowEditModal(false);
        resetEditingItem();
    };

    const editFormHandlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const newPhotoSrc = reader.result;

                setLocalImage(newPhotoSrc);
                setEditingItem((prev) => ({
                    ...prev,
                    image: newPhotoSrc, // Set data URL for preview
                    file, // Store file for upload
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const editFormHandleRemovePhoto = () => {
        if (typeof editingItem.image === 'object' && editingItem.image !== placeholderImage) {
            URL.revokeObjectURL(editingItem.image); // Revoke object URL
        }

        setLocalImage(placeholderImage);
        setEditingItem((prev) => ({
            ...prev,
            image: null, // Clear the image
        }));
        document.getElementById('fileInput').value = '';
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setCurrentPage(1); // Reset to page 1 on new search query
    };

    const toggleBulkEdit = () => {
        setShowBulkEdit(!showBulkEdit);
        setShowPriceAdjustment(false);
        setSelectedItems([]);
    };

    // Handle individual selection
    const handleSelect = (id) => {
        setSelectedItems((prev) =>
            prev.includes(id)
                ? prev.filter((itemId) => itemId !== id)
                : [...prev, id]
        );
    };

    // Handle Select All
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedItems(currentItems.map((item) => item.id));
        } else {
            setSelectedItems([]);
        }
    };

    const confirmPriceAdjustment = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch('/php/adjust-prices.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    selectedItems,
                    adjustmentType,
                    adjustmentDirection,
                    adjustmentValue: parseFloat(adjustmentValue),
                }),
            });

            const responseText = await response.text();
            const data = JSON.parse(responseText);

            if (data.status === 'success') {
                showSuccess(data.message);

                if (data.skippedItems && data.skippedItems.length > 0) {
                    setSkippedItems(data.skippedItems);
                    setShowSkippedItemsModal(true);
                }
            } else {
                setErrorWithTimeout(data.message);
            }
        } catch (error) {
            setErrorWithTimeout('An error occurred while adjusting prices');
            console.error('Error adjusting prices:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setSelectedItems([]);
                setAdjustmentType("amount");
                setAdjustmentDirection("increase");
                setAdjustmentValue("");
                setShowMultipleAdjustPriceModal(false);
                togglePriceAdjustment(false);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };

    const togglePriceAdjustment = () => {
        if (showPriceAdjustment) {
            setAdjustmentType("amount");
            setAdjustmentDirection("increase");
            setAdjustmentValue("");
        }
        setShowPriceAdjustment(!showPriceAdjustment);
    };


    return (
        <div className="items-tab-content">

            {isSubmitting && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="filter-dropdown">
                <select
                    id="kid-status-filter"
                    className="filter-btn"
                    value={selectedFilter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                >
                    <option value="all">All Items</option>
                    {(allCategories || []).map(category => (
                        <option key={category.id} value={category.id}>
                            {category.category_name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="topbar">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search items..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                </div>
                <div className='topbar-buttons'>
                    <button onClick={toggleBulkEdit} className="profiles-btn edit-btn">
                        {showBulkEdit ? 'Close Bulk Edit' : 'Bulk Edit'}
                    </button>
                    <button className="profiles-btn add-btn" onClick={handleAddItemClick}>
                        Add Item
                    </button>
                </div>
            </div>

            {showBulkEdit && (
                <div className="bulk-edit-bar">
                    <div className="bulk-edit-btns">
                        <div className='select-all'>
                            <input
                                type="checkbox"
                                className='selectall-input'
                                onChange={handleSelectAll}
                                checked={selectedItems.length === currentItems.length && currentItems.length > 0}
                            />
                            <p>Select All</p>
                        </div>

                        <p className='selected-items'>{selectedItems.length} items selected</p>
                        <button className="edit-bar-btn soldout-btn" onClick={() => {
                            if (selectedItems.length === 0) {
                                setErrorWithTimeout("Please select at least one item.");
                                return;
                            }
                            setShowMultipleSoldoutModal(true);
                        }}>
                            Mark as Sold Out
                        </button>
                        <button className="edit-bar-btn delete-btn" onClick={() => {
                            if (selectedItems.length === 0) {
                                setErrorWithTimeout("Please select at least one item.");
                                return;
                            }
                            setShowMultipleDeleteModal(true);
                        }}>
                            Delete Selected
                        </button>
                        <button
                            className="edit-bar-btn edit-btn price-btn" onClick={() => {
                                if (selectedItems.length === 0) {
                                    setErrorWithTimeout("Please select at least one item.");
                                    return;
                                }
                                togglePriceAdjustment(true);
                            }}>
                            Adjust Price
                        </button>
                    </div>

                    {showPriceAdjustment && (
                        <div className="price-adjustment-container">
                            <span>Adjust Price:</span>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentDirection"
                                    value="increase"
                                    checked={adjustmentDirection === 'increase'}
                                    onChange={(e) => setAdjustmentDirection(e.target.value)}
                                />
                                Increase
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentDirection"
                                    value="decrease"
                                    checked={adjustmentDirection === 'decrease'}
                                    onChange={(e) => setAdjustmentDirection(e.target.value)}
                                />
                                Decrease
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentType"
                                    value="amount"
                                    checked={adjustmentType === 'amount'}
                                    onChange={(e) => setAdjustmentType(e.target.value)}
                                />
                                By Amount
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentType"
                                    value="percentage"
                                    checked={adjustmentType === 'percentage'}
                                    onChange={(e) => setAdjustmentType(e.target.value)}
                                />
                                By Percentage
                            </label>

                            <input
                                type="text"
                                placeholder={`Enter ${adjustmentType === 'amount' ? 'Amount' : 'Percentage'}`}
                                value={adjustmentValue}
                                onChange={(e) => setAdjustmentValue(e.target.value)}
                                className="adjustment-value-input"
                            />
                            {adjustmentType === 'amount' ? (
                                <span className="currency-symbol">£</span>
                            ) : (
                                <span className="currency-symbol">%</span>
                            )}
                            <button className="edit-bar-btn apply-btn" onClick={() => setShowMultipleAdjustPriceModal(true)}>
                                Apply
                            </button>

                        </div>
                    )}
                </div>
            )}

            <div className="items-list">
                {currentItems.length === 0 ? (
                    <div className="no-users-found">No items found</div>
                ) : (
                    currentItems
                        .slice()
                        .sort((a, b) => a.item_name.localeCompare(b.item_name))
                        .map((item, index) => (
                            <div key={item.id} className="kid">
                                <div className="item-header">
                                    {showBulkEdit && (
                                        <input
                                            type="checkbox" className='item-checkbox'
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => handleSelect(item.id)}
                                        />
                                    )}
                                    <span className="item-number">
                                        ({(adjustedPage - 1) * itemsPerPage + index + 1})
                                    </span>

                                    {item.image ? (
                                        <img
                                            src={`https://lkofoods.com/uploads/${item.image}`}
                                            alt="Item"
                                            className="item-photo"
                                        />
                                    ) : (
                                        <img
                                            src={placeholderImage}
                                            alt="Placeholder"
                                            className="item-photo"
                                        />
                                    )}
                                    <div className="item-main-details">
                                        <span className="item-name">{item.item_name}</span>
                                        {item.price !== "0.00" && (
                                            <span className="item-price">£{item.price}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="action-buttons">
                                    <button
                                        className="edit-btn"
                                        onClick={() => handleEditClick(item)}
                                    >
                                        <i className="fas fa-pencil-alt"></i>
                                    </button>
                                    <button
                                        className="soldout-btn"
                                        onClick={() => handleMarkAsSoldout(item)}
                                    >
                                        <i className="fas fa-ban"></i>
                                    </button>
                                    <button
                                        className="delete-btn"
                                        onClick={() => handleDeleteItem(item)}
                                    >
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        ))
                )}

            </div>

            {/* Pagination Controls */}
            <div className="pagination">
                {totalPages > 0 && Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`pagination-button ${adjustedPage === index + 1 ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {error && (
                <div className="error-overlay">
                    <div className='error-overlay-container'>
                        <i className="fas fa-exclamation-triangle"></i>
                        <p className="error-overlay-message">{error}</p>
                    </div>
                </div>
            )}

            {
                isSubmitting && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )
            }

            <EditItemModal
                showEditModal={showEditModal}
                handleCloseEditModal={handleCloseEditModal}
                handleEditSubmit={handleEditSubmit}
                editingItem={editingItem}
                setEditingItem={setEditingItem}
                categories={categories}
                editFormHandlePhotoChange={editFormHandlePhotoChange}
                editFormHandleRemovePhoto={editFormHandleRemovePhoto}
                placeholderImage={placeholderImage}
                removedOptions={removedOptions}
                setRemovedOptions={setRemovedOptions}
                updatedRequiredOptions={updatedRequiredOptions}
                setUpdatedRequiredOptions={setUpdatedRequiredOptions}
                updatedOptionalOptions={updatedOptionalOptions}
                setUpdatedOptionalOptions={setUpdatedOptionalOptions}
            />

            <AddItemModal
                showAddItemModal={showAddItemModal}
                handleCloseModal={handleCloseModal}
                handleAddSubmit={handleAddSubmit}
                handlePhotoChange={handlePhotoChange}
                handleRemovePhoto={handleRemovePhoto}
                photo={photo}
                photoSrc={photoSrc}
                itemName={itemName}
                setItemName={setItemName}
                itemDescription={itemDescription}
                setItemDescription={setItemDescription}
                price={price}
                setPrice={setPrice}
                categories={categories} 
                categoryId={categoryId} 
                setCategoryId={setCategoryId} 
                subcategoryId={subcategoryId} 
                setSubcategoryId={setSubcategoryId} 
                requiredOptions={requiredOptions} 
                optionalOptions={optionalOptions}
                selectedRequiredOptions={selectedRequiredOptions}
                handleRequiredOptionSelect={handleRequiredOptionSelect}
                handleRemoveRequiredOption={handleRemoveRequiredOption}
                newRequiredOptions={newRequiredOptions}
                handleNewOptionNameChange={handleNewOptionNameChange}
                handleNewOptionPriceChange={handleNewOptionPriceChange}
                handleRemoveNewRequiredOption={handleRemoveNewRequiredOption}
                handleAddNewRequiredOption={handleAddNewRequiredOption}
                selectedOptionalOptions={selectedOptionalOptions}
                handleOptionalOptionSelect={handleOptionalOptionSelect}
                handleRemoveOptionalOption={handleRemoveOptionalOption}
                newOptionalOptions={newOptionalOptions}
                handleNewOptionalOptionNameChange={handleNewOptionalOptionNameChange}
                handleNewOptionalOptionPriceChange={handleNewOptionalOptionPriceChange}
                handleRemoveNewOptionalOption={handleRemoveNewOptionalOption}
                handleAddNewOptionalOption={handleAddNewOptionalOption}
            />


            {showSkippedItemsModal && (
                <Modal
                    title="Price Adjustment Successful"
                    onClose={() => setShowSkippedItemsModal(false)}
                    showCancel={false}
                    showSubmitButton={false}
                    className="skipped-items-modal"
                >
                    <div className='delete-modal-heading'>
                        <p>{`Prices adjusted successfully, but the following items couldn't be adjusted to avoid negative prices:`}</p>
                        <ul>
                            {skippedItems.map((itemName, index) => (
                                <li key={index}>{itemName}</li>
                            ))}
                        </ul>
                    </div>
                </Modal>
            )}


            {/* Delete Item Modal */}
            {
                showDeleteModal && (
                    <Modal
                        title="Delete Item"
                        onClose={handleCloseModal}
                        onSubmit={confirmDeleteItem}
                        submitButtonText="Delete Item"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to permanently delete this item from the database?</p>
                        </div>
                    </Modal>
                )
            }

            {/* Inactive Item Modal */}
            {
                showSoldoutModal && (
                    <Modal
                        title="Mark Item as Soldout"
                        onClose={handleCloseModal}
                        onSubmit={confirmMarkAsSoldout}
                        submitButtonText="Mark as Soldout"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to mark this item as sold out</p>
                        </div>
                    </Modal>

                )
            }
            {
                showMultipleSoldoutModal && (
                    <Modal
                        title="Mark Selected Items as Soldout"
                        onClose={handleCloseModal}
                        onSubmit={confirmMarkSelectedAsSoldout}
                        submitButtonText="Mark as Soldout"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to mark the selected items as sold out</p>
                        </div>
                    </Modal>

                )
            }
            {
                showMultipleDeleteModal && (
                    <Modal
                        title="Delete Items"
                        onClose={handleCloseModal}
                        onSubmit={confirmDeleteSelectedItems}
                        submitButtonText="Delete Items"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to permanently delete the selected items from the database?</p>
                        </div>
                    </Modal>
                )
            }
            {
                showMultipleAdjustPriceModal && (
                    <Modal
                        title="Adjust Prices"
                        onClose={handleCloseModal}
                        onSubmit={confirmPriceAdjustment}
                        submitButtonText="Adjust Prices"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to adjust the prices of the selected items?</p>
                        </div>
                    </Modal>
                )
            }
            {
                error && (
                    <div className="error-overlay">
                        <div className='error-overlay-container'>
                            <i className="fas fa-exclamation-triangle"></i>
                            <p className="error-overlay-message">{error}</p>
                        </div>
                    </div>
                )
            }



        </div >
    );
};

export default ItemsTab;
