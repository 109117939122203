import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './profiles.css';
import './home-style.css';
import ItemsTab from './ItemsTab';
import Modal from './Modal';
import Soldout from './Soldout'



const ProfilesPage = () => {
    const [activeTab, setActiveTab] = useState('items');
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [editCategoryId, setEditCategoryId] = useState('');
    const [editCategoryName, setEditCategoryName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [categories, setCategories] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [originalCategoryName, setOriginalCategoryName] = useState('');
    const [categoryType, setCategoryType] = useState("main");
    const [parentCategory, setParentCategory] = useState(null);
    const [editCategoryType, setEditCategoryType] = useState('');
    const [originalCategoryType, setOriginalCategoryType] = useState('');
    const [editParentCategory, setEditParentCategory] = useState('');
    const [originalParentCategory, setOriginalParentCategory] = useState('');


    const fetchCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('/php/categories.php');
            const data = await response.json();
            setTimeout(() => {
                setCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleAddCategoryClick = () => {
        setShowAddCategoryModal(true);
    };

    const handleCloseModal = () => {
        setShowAddCategoryModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setCategoryName('');
        setErrorWithTimeout('');
        setCategoryType('main');
        setParentCategory(null);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setErrorWithTimeout('');
        setIsSubmitting(true);

        try {
            const response = await axios.post('/php/categories.php', {
                categoryName,
                categoryType,
                parentCategory: categoryType === "sub" ? parentCategory : null,
            });

            if (response.data.status === 'error' && response.data.message === 'Category already exists') {
                setErrorWithTimeout('Category already exists');
            } else {
                setTimeout(() => {
                    setIsSubmitting(false);
                    handleCloseModal();
                    fetchCategories();
                    showSuccess('Category added successfully');
                }, 500);
            }
        } catch (error) {
            setIsSubmitting(false);
            console.error('Error adding Category:', error.response || error);

            if (error.response) {
                if (error.response.status === 409) {
                    setErrorWithTimeout('Category already exists');
                } else {
                    setErrorWithTimeout('Unexpected error occurred: ' + (error.response.data.message || ''));
                }
            } else {
                setErrorWithTimeout('Network or server error occurred.');
            }
        }
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
        resetEditFormState();
    };

    const resetEditFormState = () => {
        setEditCategoryId('');
        setEditCategoryName('');
    };

    const handleUpdateCategory = async (e) => {
        e.preventDefault();
    
        setErrorWithTimeout('');
        setIsSubmitting(true);
    
        // Prevent update if nothing has changed
        const isUnchanged =
            editCategoryName === originalCategoryName &&
            editCategoryType === originalCategoryType &&
            editParentCategory === originalParentCategory;
    
        if (isUnchanged) {
            setIsSubmitting(false);
            handleEditModalClose();
            return;
        }
    
        try {
            // Ensure correct parent category handling
            const parentCategoryId = editCategoryType === "sub" ? editParentCategory : null;
    
            const payload = {
                id: editCategoryId,
                category_name: editCategoryName.trim(), 
                category_type: editCategoryType,
                parent_id: parentCategoryId
            };
    
            const response = await axios.put(
                `/php/categories.php?id=${editCategoryId}`,
                payload
            );
    
            if (response.data.status === 'error') {
                setIsSubmitting(false);
                
                if (response.data.message === 'Category already exists') {
                    setErrorWithTimeout('Category already exists');
                } else {
                    setErrorWithTimeout(response.data.message || 'An error occurred.');
                }
                return;
            }
    
            // Success: Update the UI
            setTimeout(() => {
                setIsSubmitting(false);
                handleEditModalClose();
                fetchCategories(); // Refresh categories list
                showSuccess('Category updated successfully');
            }, 500);
    
        } catch (error) {
            setIsSubmitting(false);
            console.error('Error updating category:', error);
    
            if (error.response) {
                const { status, data } = error.response;
                if (status === 409) {
                    setErrorWithTimeout('Category already exists');
                } else {
                    setErrorWithTimeout(data?.message || 'Unexpected error occurred.');
                }
            } else {
                setErrorWithTimeout('Network or server error occurred.');
            }
        }
    };

    const handleEditCategory = (category, allCategories = []) => {
        if (!allCategories || !Array.isArray(allCategories)) {
            console.error("allCategories is undefined or not an array");
            return;
        }
    
        setEditCategoryId(category.id);
        setEditCategoryName(category.category_name); // Ensure the correct name is used
        setOriginalCategoryName(category.category_name);
        setShowEditModal(true);
    
        // Check if this category is a subcategory
        const parentCategory = allCategories.find(parent =>
            parent.subcategories && parent.subcategories.some(sub => sub.id === category.id)
        );
    
        if (parentCategory) {
            // ✅ It's a subcategory
            setEditCategoryType("sub");
            setOriginalCategoryType("sub");
            setEditParentCategory(parentCategory.id); // Correctly assign parent category ID
            setOriginalParentCategory(parentCategory.id);
        } else {
            // ✅ It's a main category
            setEditCategoryType("main");
            setOriginalCategoryType("main");
            setEditParentCategory(null);
            setOriginalParentCategory(null);
        }
    };


    const handleDeleteCategory = (category) => {
        setCategoryToDelete(category);
        setShowDeleteModal(true);
    };

    const confirmDeleteCategory = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        try {
            await axios.delete(`/php/categories.php`, {
                data: { id: categoryToDelete.id }
            });
    
            setTimeout(() => {
                setIsSubmitting(false);
                setShowDeleteModal(false);
                fetchCategories();
                showSuccess('Category deleted successfully');
            }, 500);
        } catch (error) {
            setIsSubmitting(false);
    
            if (error.response && error.response.data && error.response.data.message) {
                setErrorWithTimeout(error.response.data.message); 
            } else {
                setErrorWithTimeout('An error occurred while deleting the category.'); 
            }
    
            console.error('Error deleting category:', error);
        }
    };
    

    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const drinkCategoryIds = [8, 9, 11, 12];

    const sortedCategories = [
        ...categories.filter(category => !drinkCategoryIds.includes(Number(category.id))),
        ...categories.filter(category => drinkCategoryIds.includes(Number(category.id))),
    ];

    return (
        <main className="profiles-main">
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="profiles-container">
                {/* Sidebar */}
                <div className="sidebar">
                    <div className="tab-links">
                        <button
                            className={activeTab === 'items' ? 'active' : ''}
                            onClick={() => handleTabClick('items')}
                        >
                            Instock Items
                        </button>
                        <button
                            className={activeTab === 'outofstock' ? 'active' : ''}
                            onClick={() => handleTabClick('outofstock')}
                        >
                            Sold Out
                        </button>
                        <button
                            className={activeTab === 'categories' ? 'active' : ''}
                            onClick={() => handleTabClick('categories')}
                        >
                            Categories
                        </button>
                    </div>
                </div>

                {/* Main Content */}
                <div className="main-content">
                    {/* Conditional rendering based on activeTab */}
                    {activeTab === 'categories' && (
                        <>
                            <div className="top-right top-right-user">
                                <button className="profiles-btn add-btn" onClick={handleAddCategoryClick}>
                                    Add Category
                                </button>
                            </div>
                            <div className="categories-list">
                                {sortedCategories.length === 0 ? (
                                    <div className="no-categories-found">No categories found.</div>
                                ) : (
                                    sortedCategories.map((category, index) => (
                                        <div key={category.id} className="category-item">
                                            {/* Main Category */}
                                            <div className="user">
                                                <div className='user-info'>
                                                    <span className="user-number">({index + 1})</span>
                                                    <span className='person-name'>{category.category_name}</span>
                                                </div>
                                                <div className='action-buttons'>
                                                    <button className="edit-btn" onClick={() => handleEditCategory(category, categories)}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button className="delete-btn" onClick={() => handleDeleteCategory(category)}>
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            {/* Subcategories */}
                                            {category.subcategories.length > 0 && (
                                                <div className="subcategories-list">
                                                    {category.subcategories.map((subCategory, subIndex) => (
                                                        <div key={subCategory.id} className="subcategory-item user">
                                                            <div className='user-info subcategory'>
                                                                <span className="subcategory-number">({String.fromCharCode(97 + subIndex)})</span>
                                                                <span className='person-name'>{subCategory.category_name}</span>
                                                            </div>
                                                            <div className='action-buttons'>
                                                                <button className="edit-btn" onClick={() => handleEditCategory(subCategory, categories)}>
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button className="delete-btn" onClick={() => handleDeleteCategory(subCategory)}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>

                        </>
                    )}

                    {activeTab === 'items' && (
                        <ItemsTab />
                    )}

                    {activeTab === 'outofstock' && (
                        <Soldout />
                    )}

                    {/* Add Category Modal */}
                    {showAddCategoryModal && (
                        <Modal
                            title="Add Category"
                            onClose={handleCloseModal}
                            onSubmit={handleFormSubmit}
                            submitButtonText="Add Category"
                            showCancel={false}
                            className="modal-content"
                            showSubmitButton={true}
                        >
                            <div className="profiles-form">
                                {/* Category Name Input */}
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={categoryName}
                                        placeholder='Enter Category Name'
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* Select Category Type */}
                                <div className="category-form-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="main"
                                            checked={categoryType === "main"}
                                            onChange={() => {
                                                setCategoryType("main");
                                                setParentCategory(null);
                                            }}
                                        />
                                        Main Category
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="sub"
                                            checked={categoryType === "sub"}
                                            onChange={() => setCategoryType("sub")}
                                        />
                                        Sub Category
                                    </label>
                                </div>

                                {/* Parent Category Dropdown (Only if Subcategory is Selected) */}
                                {categoryType === "sub" && (
                                    <div className="form-group">
                                        <select value={parentCategory} onChange={(e) => setParentCategory(e.target.value)} required>
                                            <option value="">Select Parent Category</option>
                                            {sortedCategories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.category_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </Modal>
                    )}

                    {error && (
                        <div className="error-overlay">
                            <div className='error-overlay-container'>
                                <i className="fas fa-exclamation-triangle"></i>
                                <p className="error-overlay-message">{error}</p>
                            </div>
                        </div>
                    )}

                    {isSubmitting && (
                        <div className="spinner-overlay">
                            <div className="spinner"></div>
                        </div>
                    )}

                    {/* Edit Category Modal */}
                    {showEditModal && (
                        <Modal
                            title={'Edit Category'}
                            onClose={handleEditModalClose}
                            onSubmit={handleUpdateCategory}
                            submitButtonText={'Update'}
                            showCancel={false}
                            showSubmitButton={true}
                            className="modal-content"
                        >
                            <div className="profiles-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        placeholder="Category Name"
                                        value={editCategoryName}
                                        onChange={(e) => setEditCategoryName(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* Category Type Selection */}
                                <div className="category-form-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="main"
                                            checked={editCategoryType === "main"}
                                            onChange={() => {
                                                setEditCategoryType("main");
                                                setEditParentCategory(null); 
                                            }}
                                        />
                                        Main Category
                                    </label>
                                    <label style={{ marginLeft: "10px" }}>
                                        <input
                                            type="radio"
                                            value="sub"
                                            checked={editCategoryType === "sub"}
                                            onChange={() => setEditCategoryType("sub")}
                                        />
                                        Sub Category
                                    </label>
                                </div>

                                {/* Parent Category Selection (Only for Subcategories) */}
                                {editCategoryType === "sub" && (
                                    <div className="form-group">
                                        <label>Select Parent Category:</label>
                                        <select
                                            value={editParentCategory || ""}
                                            onChange={(e) => setEditParentCategory(e.target.value)}
                                        >
                                            <option value="">-- Select Parent Category --</option>
                                            {categories.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.category_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </Modal>
                    )}



                    {/* Delete Category Modal */}
                    {showDeleteModal && (
                        <Modal
                            title="Delete Category"
                            onClose={() => setShowDeleteModal(false)}
                            onSubmit={confirmDeleteCategory}
                            submitButtonText="Delete"
                            showCancel={true}
                            showSubmitButton={true}
                            className="modal-content delete-modal-content"
                        >
                            <div>
                                <p className='delete-modal-heading'>Are you sure you want to delete this category?</p>
                            </div>
                        </Modal>
                    )}

                </div>
            </div>
        </main>
    );
};

export default ProfilesPage;
