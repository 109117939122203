import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import './profiles.css';
import './home-style.css';
import placeholderImage from '../img/placeholder-img.png';


const Soldout = () => {
    const [itemToInstock, setItemToInstock] = useState(null);
    const [showInstockModal, setShowInstockModal] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const itemsPerPage = 50;
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const searchResults = filteredItems.filter(item =>
        item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const totalPages = Math.ceil(searchResults.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const adjustedPage = currentPage > totalPages ? 1 : currentPage;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [adjustmentType, setAdjustmentType] = useState('amount');
    const [adjustmentDirection, setAdjustmentDirection] = useState('increase');
    const [adjustmentValue, setAdjustmentValue] = useState('');
    const [showPriceAdjustment, setShowPriceAdjustment] = useState(false);
    const [skippedItems, setSkippedItems] = useState([]);
    const [showMultipleInstockModal, setShowMultipleInstockModal] = useState(false);
    const [showMultipleAdjustPriceModal, setShowMultipleAdjustPriceModal] = useState(false);
    const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
    const [showSkippedItemsModal, setShowSkippedItemsModal] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const [allCategories, setAllCategories] = useState([]);
    
    useEffect(() => {
    }, [filteredItems]);
    

    const fetchMenuItems = async (selectedFilter) => {
        setIsSubmitting(true);
        try {
            const response = await fetch("/php/fetch-soldout-items.php");
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            if (responseData.status !== 'success' || !Array.isArray(responseData.data)) {
                throw new Error('Invalid response data');
            }

            setMenuItems(responseData.data);
            // Filter items based on the selectedFilter
            if (selectedFilter && selectedFilter !== "all") {
                const selectedCategoryId = parseInt(selectedFilter);
                const filtered = responseData.data.filter(item => item.category_id === selectedCategoryId);
                setFilteredItems(filtered);
            } else {
                setFilteredItems(responseData.data);
            }

        } catch (error) {
            console.error("Error fetching menu items:", error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

   useEffect(() => {
           fetchMenuItems(selectedFilter);
           fetchAllCategories();
       }, [selectedFilter]);

    const fetchAllCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('/php/fetch-categories.php');
            const data = await response.json();
            setTimeout(() => {
                setAllCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };


    const handleCloseModal = () => {
        setShowDeleteItemModal(false);
        setShowMultipleInstockModal(false);
        setShowMultipleDeleteModal(false);
        setShowMultipleAdjustPriceModal(false);
    };

    const handleMarkAsInstock = (item) => {
        setItemToInstock(item);
        setShowInstockModal(true);
    };

    const confirmMarkAsInstock = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToInstock || !itemToInstock.id) {
            setErrorWithTimeout('Invalid item selected for marking as available');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`/php/soldout-items.php?id=${itemToInstock.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...itemToInstock,
                    in_stock: 1
                }),
            });

            const data = await response.json();
            if (data.status === 'success') {
                showSuccess('Item marked as available');
            } else {
                setErrorWithTimeout('Error marking item as available');
            }
        } catch (error) {
            console.error('Error during PUT request:', error.message);
            setErrorWithTimeout('Error marking item as available');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowInstockModal(false);
                fetchMenuItems();
            }, 500);
        }
    };

    const handleDeleteItem = (item) => {
        setItemToDelete(item);
        setShowDeleteItemModal(true);
    };

    const confirmDeleteItem = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!itemToDelete || !itemToDelete.id) {
            setErrorWithTimeout('Invalid item selected for deletion');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.delete('/php/add-new-item.php', {
                data: {
                    id: itemToDelete.id
                }
            });

            if (response.data.status === 'success') {
                showSuccess('Item deleted successfully');
            } else {
                setErrorWithTimeout('Error deleting item');
            }
        } catch (error) {
            setErrorWithTimeout('Error deleting item');
            console.error('Error deleting item:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems();
            }, 500);
        }
    };


    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const toggleBulkEdit = () => {
        setShowBulkEdit(!showBulkEdit);
        setShowPriceAdjustment(false);
        setSelectedItems([]);
    };

    const handleFilterChange = (selectedCategory) => {
        setSelectedFilter(selectedCategory);
        setCurrentPage(1);
        if (selectedCategory === "all") {
            setFilteredItems(menuItems);
        } else {
            const selectedCategoryId = parseInt(selectedCategory);
            const filtered = menuItems.filter(item => item.category_id === selectedCategoryId);
            setFilteredItems(filtered);
        }
    };

    // Handle individual selection
    const handleSelect = (id) => {
        setSelectedItems((prev) =>
            prev.includes(id)
                ? prev.filter((itemId) => itemId !== id)
                : [...prev, id]
        );
    };

    // Handle Select All
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedItems(currentItems.map((item) => item.id));
        } else {
            setSelectedItems([]);
        }
    };

    const confirmPriceAdjustment = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch('/php/adjust-prices.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    selectedItems,
                    adjustmentType,
                    adjustmentDirection,
                    adjustmentValue: parseFloat(adjustmentValue),
                }),
            });

            const responseText = await response.text();
            const data = JSON.parse(responseText);

            if (data.status === 'success') {
                showSuccess(data.message);

                if (data.skippedItems && data.skippedItems.length > 0) {
                    setSkippedItems(data.skippedItems);
                    setShowSkippedItemsModal(true);
                }
            } else {
                setErrorWithTimeout(data.message);
            }
        } catch (error) {
            setErrorWithTimeout('An error occurred while adjusting prices');
            console.error('Error adjusting prices:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setSelectedItems([]);
                setAdjustmentType("amount");
                setAdjustmentDirection("increase");
                setAdjustmentValue("");
                setShowMultipleAdjustPriceModal(false);
                togglePriceAdjustment(false);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };

    const togglePriceAdjustment = () => {
        if (showPriceAdjustment) {
            setAdjustmentType("amount");
            setAdjustmentDirection("increase");
            setAdjustmentValue("");
        }
        setShowPriceAdjustment(!showPriceAdjustment);
    };

    const confirmMarkSelectedAsInstock = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        try {
            const response = await fetch("/php/multiple-soldout-items.php", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    items: selectedItems.map((id) => ({ id, in_stock: 1 })),
                }),
            });
    
            const result = await response.json();
    
            if (result.status === "success") {
                showSuccess(result.message);
            } else {
                setErrorWithTimeout(result.message);
            }
    
        } catch (error) {
            setErrorWithTimeout("An error occurred while marking items as in stock");
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowMultipleInstockModal(false);
                setSelectedItems([]);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };
    
    const confirmDeleteSelectedItems = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const promises = selectedItems.map((itemId) =>
                axios.delete('/php/add-new-item.php', {
                    data: { id: itemId }
                })
            );

            const responses = await Promise.all(promises);

            const successCount = responses.filter((response) => response.data.status === 'success').length;
            const failedCount = responses.length - successCount;

            if (successCount > 0) {
                showSuccess(`${successCount} item(s) deleted successfully`);
            }
            if (failedCount > 0) {
                setErrorWithTimeout(`${failedCount} item(s) failed to delete`);
            }

        } catch (error) {
            setErrorWithTimeout('An error occurred while deleting items');
            console.error('Error deleting items:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                setSelectedItems([]);
                fetchMenuItems(selectedFilter);
            }, 500);
        }
    };

    return (
        <div>
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}

            <div className="filter-dropdown">
                <select
                    id="kid-status-filter"
                    className="filter-btn"
                    value={selectedFilter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                >
                    <option value="all">All Items</option>
                    {allCategories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.category_name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="topbar">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search items..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                </div>
                <div className='topbar-buttons'>
                    <button onClick={toggleBulkEdit} className="profiles-btn edit-btn">
                        {showBulkEdit ? 'Close Bulk Edit' : 'Bulk Edit'}
                    </button>
                </div>
            </div>

            {showBulkEdit && (
                <div className="bulk-edit-bar">
                    <div className="bulk-edit-btns">
                        <div className='select-all'>
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedItems.length === currentItems.length && currentItems.length > 0}
                            />
                            <p>Select All</p>
                        </div>

                        <p>{selectedItems.length} items selected</p>
                        <button className="edit-bar-btn soldout-btn" onClick={() => {
                            if (selectedItems.length === 0) {
                                setErrorWithTimeout("Please select at least one item.");
                                return;
                            }
                            setShowMultipleInstockModal(true);
                        }}>
                            Mark as In Stock
                        </button>
                        <button className="edit-bar-btn delete-btn" onClick={() => {
                            if (selectedItems.length === 0) {
                                setErrorWithTimeout("Please select at least one item.");
                                return;
                            }
                            setShowMultipleDeleteModal(true);
                        }}>
                            Delete Selected
                        </button>
                        <button
                            className="edit-bar-btn edit-btn price-btn" onClick={() => {
                                if (selectedItems.length === 0) {
                                    setErrorWithTimeout("Please select at least one item.");
                                    return;
                                }
                                togglePriceAdjustment(true);
                            }}>
                            Adjust Price
                        </button>
                    </div>

                    {showPriceAdjustment && (
                        <div className="price-adjustment-container">
                            <span>Adjust Price:</span>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentDirection"
                                    value="increase"
                                    checked={adjustmentDirection === 'increase'}
                                    onChange={(e) => setAdjustmentDirection(e.target.value)}
                                />
                                Increase
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentDirection"
                                    value="decrease"
                                    checked={adjustmentDirection === 'decrease'}
                                    onChange={(e) => setAdjustmentDirection(e.target.value)}
                                />
                                Decrease
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentType"
                                    value="amount"
                                    checked={adjustmentType === 'amount'}
                                    onChange={(e) => setAdjustmentType(e.target.value)}
                                />
                                By Amount
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="adjustmentType"
                                    value="percentage"
                                    checked={adjustmentType === 'percentage'}
                                    onChange={(e) => setAdjustmentType(e.target.value)}
                                />
                                By Percentage
                            </label>

                            <input
                                type="text"
                                placeholder={`Enter ${adjustmentType === 'amount' ? 'Amount' : 'Percentage'}`}
                                value={adjustmentValue}
                                onChange={(e) => setAdjustmentValue(e.target.value)}
                                className="adjustment-value-input"
                            />
                            {adjustmentType === 'amount' ? (
                                <span className="currency-symbol">₦</span>
                            ) : (
                                <span className="currency-symbol">%</span>
                            )}
                            <button className="edit-bar-btn apply-btn" onClick={() => setShowMultipleAdjustPriceModal(true)}>
                                Apply
                            </button>

                        </div>
                    )}
                </div>
            )}

            <div className="items-list">

                {currentItems.length === 0 ? (
                    <div className="no-users-found">No items found.</div>
                ) : (
                    currentItems.map((item, index) => (
                        <div key={item.id} className="kid">
                            <div className='item-header'>
                                {showBulkEdit && (
                                    <input
                                        type="checkbox" className='item-checkbox'
                                        checked={selectedItems.includes(item.id)}
                                        onChange={() => handleSelect(item.id)}
                                    />
                                )}
                                <span className="item-number">({(adjustedPage - 1) * itemsPerPage + index + 1})</span>
                                {item.image ? (
                                    <img
                                        src={`https://lkofoods.com/uploads/${item.image}`}
                                        alt="Item"
                                        className="item-photo"
                                    />
                                ) : (
                                    <img
                                        src={placeholderImage}
                                        alt="Placeholder"
                                        className="item-photo"
                                    />
                                )}
                                <div className='item-main-details'>
                                    <span className="item-name">{item.item_name}</span>
                                    {item.price !== "0.00" && <span className="item-price">£{item.price}</span>}
                                </div>
                            </div>

                            <div className='action-buttons'>
                                <button className="restock-btn" onClick={() => handleMarkAsInstock(item)}>
                                    <i className="fas fa-check-double"></i>
                                </button>
                                <button className="delete-btn" onClick={() => handleDeleteItem(item)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>

                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Pagination Controls */}
            <div className="pagination">
                {totalPages > 0 && Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`pagination-button ${adjustedPage === index + 1 ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {error && (
                <div className="error-overlay">
                    <div className='error-overlay-container'>
                        <i className="fas fa-exclamation-triangle"></i>
                        <p className="error-overlay-message">{error}</p>
                    </div>
                </div>
            )}


            {isSubmitting && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}

            {showDeleteItemModal && (
                <Modal
                    title="Delete Item"
                    onClose={() => setShowDeleteItemModal(false)}
                    onSubmit={confirmDeleteItem}
                    submitButtonText="Delete"
                    showCancel={true}
                    showSubmitButton={true}
                    className="modal-content delete-modal-content"
                >
                    <div>
                        <p className='delete-modal-heading'>Are you sure you want to delete this item?</p>
                    </div>
                </Modal>
            )}

            {
                showInstockModal && (
                    <Modal
                        title="Mark Item as Available"
                        onClose={handleCloseModal}
                        onSubmit={confirmMarkAsInstock}
                        submitButtonText="Mark as Available"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to mark this item as available?</p>
                        </div>
                    </Modal>
                )
            }

            {
                showMultipleInstockModal && (
                    <Modal
                        title="Mark Selected Items as In Stock"
                        onClose={handleCloseModal}
                        onSubmit={confirmMarkSelectedAsInstock}
                        submitButtonText="Mark as In Stock"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to mark the selected items as in stock</p>
                        </div>
                    </Modal>

                )
            }
            {showSkippedItemsModal && (
                            <Modal
                                title="Price Adjustment Successful"
                                onClose={() => setShowSkippedItemsModal(false)}
                                showCancel={false}
                                showSubmitButton={false}
                                className="skipped-items-modal"
                            >
                                <div className='delete-modal-heading'>
                                    <p>{`Prices adjusted successfully, but the following items couldn't be adjusted to avoid negative prices:`}</p>
                                    <ul>
                                        {skippedItems.map((itemName, index) => (
                                            <li key={index}>{itemName}</li>
                                        ))}
                                    </ul>
                                </div>
                            </Modal>
                        )}
            {
                showMultipleDeleteModal && (
                    <Modal
                        title="Delete Items"
                        onClose={handleCloseModal}
                        onSubmit={confirmDeleteSelectedItems}
                        submitButtonText="Delete Items"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to permanently delete the selected items from the database?</p>
                        </div>
                    </Modal>
                )
            }
            {
                showMultipleAdjustPriceModal && (
                    <Modal
                        title="Adjust Prices"
                        onClose={handleCloseModal}
                        onSubmit={confirmPriceAdjustment}
                        submitButtonText="Adjust Prices"
                        showCancel={true}
                        showSubmitButton={true}
                        className="delete-modal-content"
                    >
                        <div className="delete-modal-heading">
                            <p>Are you sure you want to adjust the prices of the selected items?</p>
                        </div>
                    </Modal>
                )
            }

        </div>
    );
};
export default Soldout;