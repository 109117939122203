import React, { useState, useEffect } from 'react';
import Modal from './Modal';


const EditItemModal = ({
    showEditModal,
    handleCloseEditModal,
    handleEditSubmit,
    editingItem,
    setEditingItem,
    categories,
    editFormHandlePhotoChange,
    editFormHandleRemovePhoto,
    placeholderImage,
    setRemovedOptions,
    updatedRequiredOptions,  
    setUpdatedRequiredOptions,  
    updatedOptionalOptions,  
    setUpdatedOptionalOptions  
}) => {
    const [localImage, setLocalImage] = useState(
        typeof editingItem.image === 'string' && editingItem.image.includes('../')
            ? `https://lkofoods.com/${editingItem.image}`
            : editingItem.image || placeholderImage
    );

    const [existingRequiredOptions, setExistingRequiredOptions] = useState([]);
    const [existingOptionalOptions, setExistingOptionalOptions] = useState([]);
    const [selectedUpdatedRequiredOption, setselectedUpdatedRequiredOption] = useState('');
    const [selectedUpdatedOptionalOption, setselectedUpdatedOptionalOption] = useState('');



    // Add this where you populate existing options
    useEffect(() => {
        if (showEditModal) {
            setselectedUpdatedRequiredOption(""); // Reset required dropdown
            setselectedUpdatedOptionalOption(""); // Reset optional dropdown
    
            fetch("/php/fetch-options.php")
                .then((response) => response.json())
                .then((data) => {
                    setExistingRequiredOptions(data.required_options || []);
                    setExistingOptionalOptions(data.optional_options || []);
                });
        }
    }, [showEditModal]);
    


    useEffect(() => {
        if (!editingItem.id) return;

        const controller = new AbortController();

        fetch(`/php/fetch-item-options.php?item_id=${editingItem.id}`, {
            signal: controller.signal
        })
            .then((res) => res.json())
            .then((data) => {
                // IMPORTANT: Only set the options that are ACTUALLY linked to this item
                setUpdatedRequiredOptions(data.required_options?.map(option => ({
                    ...option,
                    isEditable: true
                })) || []);

                setUpdatedOptionalOptions(data.optional_options?.map(option => ({
                    ...option,
                    isEditable: true
                })) || []);
            })
            .catch((err) => {
                if (err.name !== 'AbortError') {
                }
            });

        return () => controller.abort();
    }, [editingItem.id]);


    const handleRemoveOption = (type, optionId) => {
        if (!optionId) return;

        // Add to removed options array
        setRemovedOptions(prev => [...prev, optionId]);

        // Remove from current options
        if (type === "required") {
            setUpdatedRequiredOptions(prev => prev.filter(opt => opt.id !== optionId));
        } else {
            setUpdatedOptionalOptions(prev => prev.filter(opt => opt.id !== optionId));
        }
    };

    // Handle adding a new option
    const handleAddOption = (type) => {
        const newOption = {
            id: null, // Use null for new options
            option_name: "",
            option_price: "",
            isEditable: true
        };
    
        // Verify we're adding to the correct array
        if (type === "required") {
            setUpdatedRequiredOptions(prev => [...prev, newOption]);
        } else {
            setUpdatedOptionalOptions(prev => [...prev, newOption]);
        }
    };

    // In the handleOptionChange function:
    const handleOptionChange = (type, index, field, value) => {
        if (type === "required") {
            setUpdatedRequiredOptions(prev => {
                const updatedOptions = [...prev];
                updatedOptions[index] = {
                    ...updatedOptions[index],
                    [field]: value
                };
                return updatedOptions;
            });
        } else {
            setUpdatedOptionalOptions(prev => {
                const updatedOptions = [...prev];
                updatedOptions[index] = {
                    ...updatedOptions[index],
                    [field]: value
                };
                return updatedOptions;
            });
        }
    };

    const handleSelectExistingOption = (type, optionId) => {
        if (!optionId) return;
    
        const optionsPool = type === "required" 
            ? existingRequiredOptions 
            : existingOptionalOptions;
    
        const selectedOption = optionsPool.find(opt => opt.id == optionId);
        
        if (!selectedOption) {
            return;
        }
    
        // Verify this option isn't already added
        const currentOptions = type === "required" ? updatedRequiredOptions : updatedOptionalOptions;
        if (currentOptions.some(opt => opt.id == optionId)) {
            return;
        }
    
        // Add ONLY the selected option
        const formattedOption = { 
            ...selectedOption,
            isEditable: false
        };
    
        if (type === "required") {
            setUpdatedRequiredOptions(prev => [...prev, formattedOption]);
        } else {
            setUpdatedOptionalOptions(prev => [...prev, formattedOption]);
        }
    };

    return (
        showEditModal && (
            <Modal
                title="Edit Menu Item"
                onClose={handleCloseEditModal}
                onSubmit={handleEditSubmit}
                submitButtonText="Update Item"
                showCancel={false}
                showSubmitButton={true}
                className="modal-content"
            >
                <div className="profiles-form">
                    {/* Photo Field */}
                    <div className="form-group">
                        <label>Photo</label>
                        {editingItem.image ? (
                            <img
                                className="item-photo item-photo2"
                                src={
                                    typeof editingItem.image === 'string'
                                        ? editingItem.image.includes('../')
                                            ? editingItem.image.replace('../', 'https://lkofoods.com/')
                                            : editingItem.image
                                        : localImage
                                }
                                alt="Menu Item"
                                style={{ width: '250px', height: '150px', borderRadius: '10%', margin: 'auto' }}
                            />
                        ) : (
                            <img
                                src={placeholderImage}
                                alt="Placeholder"
                                className="item-photo item-photo2"
                                style={{ width: '250px', height: '150px', borderRadius: '10%', margin: 'auto' }}
                            />
                        )}

                        {/* Hidden file input field */}
                        <input
                            type="file"
                            accept="image/jpeg, image/jpg, image/png, image/webp"
                            onChange={editFormHandlePhotoChange}
                            id="fileInput"
                            style={{ display: 'none' }}
                        />

                        {/* Buttons for choosing or removing the photo */}
                        <div className="photo-buttons">
                            <label htmlFor="fileInput" className="photo-btn">Choose file</label>
                            {editingItem.image && (
                                <label className="photo-btn photo-btn2" onClick={editFormHandleRemovePhoto}>
                                    <i className="fas fa-times"></i>
                                </label>
                            )}
                        </div>

                        <div id="fileName">
                            {typeof editingItem.image === "string" && editingItem.image.includes("/")
                                ? editingItem.image.split("/").pop()
                                : "No file chosen"}
                        </div>
                    </div>

                    {/* Item Name */}
                    <div className="form-group">
                        <label>Item Name</label>
                        <input
                            type="text"
                            value={editingItem.item_name}
                            onChange={(e) => setEditingItem({ ...editingItem, item_name: e.target.value })}
                            required
                        />
                    </div>

                    {/* Item Description */}
                    <div className="form-group">
                        <label>Item Description</label>
                        <textarea
                            value={editingItem.item_description}
                            rows={4}
                            onChange={(e) => setEditingItem({ ...editingItem, item_description: e.target.value })}
                        />
                    </div>

                    {/* Price */}
                    <div className="form-group">
                        <label>Price (£)</label>
                        <input
                            type="number"
                            step="0.1"
                            value={editingItem.price}
                            onChange={(e) => setEditingItem({ ...editingItem, price: e.target.value })}
                        />
                    </div>

                    {/* Category */}
                    <div className="form-group">
                        <label>Category</label>
                        <select
                            value={editingItem.category_id}
                            onChange={(e) => {
                                const selectedCategory = e.target.value;
                                setEditingItem({
                                    ...editingItem,
                                    category_id: selectedCategory,
                                    subcategory_id: "" // Reset subcategory when category changes
                                });
                            }}
                            required
                        >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.category_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Subcategory Dropdown */}
                    {editingItem.category_id &&
                        categories.find((cat) => cat.id === editingItem.category_id)?.subcategories.length > 0 && (
                            <div className="form-group">
                                <label>Sub-category</label>
                                <select
                                    value={editingItem.subcategory_id || ""}
                                    onChange={(e) => setEditingItem({ ...editingItem, subcategory_id: e.target.value })}
                                >
                                    <option value="">None</option>
                                    {categories
                                        .find((cat) => cat.id === editingItem.category_id)
                                        ?.subcategories.map((sub) => (
                                            <option key={sub.id} value={sub.id}>
                                                {sub.category_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}

                    {/* Required Options */}
                    <div className="form-group">
                        <label>Required Options (Radio Buttons)</label>

                        {/* Dropdown for selecting existing required options */}
                        <select
                            value={selectedUpdatedRequiredOption}
                            onChange={(e) => {
                                setselectedUpdatedRequiredOption(e.target.value);
                                handleSelectExistingOption("required", e.target.value);
                            }}
                        >
                            <option value="">Select an option</option>
                            {existingRequiredOptions.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.option_name} (£{option.option_price})
                                </option>
                            ))}
                        </select>

                        {/* Display selected and retrieved required options */}
                        {updatedRequiredOptions.map((option, index) => (
                            <div key={option.id} className="new-option-fields">
                                <input
                                    type="text"
                                    value={option.option_name}
                                    readOnly={!option.isEditable}
                                    disabled={!option.isEditable}
                                    onChange={(e) =>
                                        handleOptionChange("required", index, "option_name", e.target.value)
                                    }
                                />
                                <input
                                    type="number"
                                    step="0.1"
                                    value={option.option_price}
                                    readOnly={!option.isEditable}
                                    disabled={!option.isEditable}
                                    onChange={(e) =>
                                        handleOptionChange("required", index, "option_price", e.target.value)
                                    }
                                />
                                <button type="button" onClick={() => handleRemoveOption("required", option.id)} className="remove-option-btn">
                                    X
                                </button>
                            </div>
                        ))}

                        {/* Button to manually add a required option */}
                        <button type="button" onClick={() => handleAddOption("required")}>Add Option</button>
                    </div>



                    {/* Optional Options */}
                    <div className="form-group">
                        <label>Optional Options (Checkboxes)</label>

                        {/* Dropdown for selecting existing optional options */}
                        <select
                            value={selectedUpdatedOptionalOption}
                            onChange={(e) => {
                                setselectedUpdatedOptionalOption(e.target.value);
                                handleSelectExistingOption("optional", e.target.value);
                            }}
                        >
                            <option value="">Select an option</option>
                            {existingOptionalOptions.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.option_name} (£{option.option_price})
                                </option>
                            ))}
                        </select>

                        {/* Display selected and retrieved optional options */}
                        {updatedOptionalOptions.map((option, index) => (
                            <div key={option.id} className="new-option-fields">
                                <input
                                    type="text"
                                    value={option.option_name}
                                    readOnly={!option.isEditable}
                                    disabled={!option.isEditable}
                                    onChange={(e) =>
                                        handleOptionChange("optional", index, "option_name", e.target.value)
                                    }
                                />
                                <input
                                    type="number"
                                    step="0.1"
                                    value={option.option_price}
                                    readOnly={!option.isEditable}
                                    disabled={!option.isEditable}
                                    onChange={(e) =>
                                        handleOptionChange("optional", index, "option_price", e.target.value)
                                    }
                                />
                                <button type="button" onClick={() => handleRemoveOption("optional", option.id)} className="remove-option-btn">
                                    X
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={() => handleAddOption("optional")}>Add Option</button>
                    </div>

                </div>
            </Modal>
        )
    );
};

export default EditItemModal;
