import React from "react";
import Modal from "./Modal";
import placeholderImage from '../img/placeholder-img.png';

const AddItemModal = ({
    showAddItemModal,
    handleCloseModal,
    handleAddSubmit,
    photoSrc,
    handlePhotoChange,
    handleRemovePhoto,
    photo,
    itemName,
    setItemName,
    itemDescription,
    setItemDescription,
    price,
    setPrice,
    categoryId,
    setCategoryId,
    subcategoryId,
    setSubcategoryId,
    categories,
    requiredOptions,
    optionalOptions,
    handleRequiredOptionSelect,
    selectedRequiredOptions,
    handleRemoveRequiredOption,
    newRequiredOptions,
    handleNewOptionNameChange,
    handleNewOptionPriceChange,
    handleRemoveNewRequiredOption,
    handleAddNewRequiredOption,
    handleOptionalOptionSelect,
    selectedOptionalOptions,
    handleRemoveOptionalOption,
    newOptionalOptions,
    handleNewOptionalOptionNameChange,
    handleNewOptionalOptionPriceChange,
    handleRemoveNewOptionalOption,
    handleAddNewOptionalOption
}) => {
    
    return (
        showAddItemModal && (
            <Modal
                title="Add Menu Item"
                onClose={handleCloseModal}
                onSubmit={handleAddSubmit}
                submitButtonText="Add Menu Item"
                showCancel={false}
                showSubmitButton={true}
                className="modal-content"
                requiredOptions={requiredOptions}
                optionalOptions={optionalOptions}
            >
                <div className="profiles-form">
                    {/* Photo Field */}
                    <div className="form-group">
                        <label>Photo</label>
                        <img
                            className="item-photo"
                            src={photoSrc || placeholderImage}
                            alt="Menu Item"
                            style={{ width: "250px", height: "150px", borderRadius: "10%", margin: "auto" }}
                        />
                        <input
                            type="file"
                            accept="image/jpeg, image/jpg, image/png, image/webp"
                            onChange={handlePhotoChange}
                            id="fileInput"
                            style={{ display: "none" }}
                        />
                        <div className="photo-buttons">
                            <label htmlFor="fileInput" className="photo-btn">
                                Choose file
                            </label>
                            {photo && (
                                <label className="photo-btn photo-btn2" onClick={handleRemovePhoto}>
                                    <i className="fas fa-times"></i>
                                </label>
                            )}
                        </div>
                        <div id="fileName">{photo ? photo.name : "No file chosen"}</div>
                    </div>

                    {/* Item Name */}
                    <div className="form-group">
                        <label>Item Name</label>
                        <input type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} required />
                    </div>

                    {/* Item Description */}
                    <div className="form-group">
                        <label>Item Description</label>
                        <textarea value={itemDescription} rows={4} onChange={(e) => setItemDescription(e.target.value)} />
                    </div>

                    {/* Price */}
                    <div className="form-group">
                        <label>Price (£)</label>
                        <input type="number" step="0.01" value={price} onChange={(e) => setPrice(e.target.value)} />
                    </div>

                    {/* Category */}
                    <div className="form-group">
                        <label>Category</label>
                        <div className="select-wrapper">
                            <select
                                value={categoryId}
                                onChange={(e) => {
                                    setCategoryId(e.target.value);
                                    setSubcategoryId("");
                                }}
                                required
                            >
                                <option value="">Select Category</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Subcategory */}
                    {categoryId &&
                        categories.find((cat) => cat.id === categoryId)?.subcategories.length > 0 && (
                            <div className="form-group">
                                <label>Select Sub-category</label>
                                <div className="select-wrapper">
                                    <select value={subcategoryId} onChange={(e) => setSubcategoryId(e.target.value)}>
                                        <option value="">None</option>
                                        {categories
                                            .find((cat) => cat.id === categoryId)
                                            ?.subcategories.map((sub) => (
                                                <option key={sub.id} value={sub.id}>
                                                    {sub.category_name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        )}

                    {/* Required Options */}
                    <div className="form-group">
                        <label>Required Options (Radio Buttons)</label>
                        <div className="select-wrapper">
                            <select onChange={(e) => handleRequiredOptionSelect(e.target.value)} defaultValue="">
                                <option value="">Select Required Option</option>
                                {requiredOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.option_name} (£{option.option_price})
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedRequiredOptions.map((option, index) => (
                            <div key={index} className="selected-option">
                                <input type="text" value={`${option.option_name} - £${option.option_price}`} readOnly />
                                <button onClick={() => handleRemoveRequiredOption(index)} className="remove-option-btn">X</button>
                            </div>
                        ))}

                        {newRequiredOptions.map((option, index) => (
                            <div key={index} className="new-option-fields">
                                <input type="text" value={option.option_name} onChange={(e) => handleNewOptionNameChange(e, index)} placeholder="Option Name" />
                                <input type="number" step="0.01" value={option.option_price} onChange={(e) => handleNewOptionPriceChange(e, index)} placeholder="Price" />
                                <button type="button" onClick={() => handleRemoveNewRequiredOption(index)} className="remove-option-btn">X</button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddNewRequiredOption} >Add Option</button>
                    </div>

                    {/* Optional Options */}
                    <div className="form-group">
                        <label>Optional Options (Checkboxes)</label>
                        <div className="select-wrapper">
                            <select onChange={(e) => handleOptionalOptionSelect(e.target.value)} defaultValue="">
                                <option value="">Select Optional Option</option>
                                {optionalOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.option_name} (+£{option.option_price})
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedOptionalOptions.map((option, index) => (
                            <div key={index} className="selected-option">
                                <input type="text" value={`${option.option_name} - £${option.option_price}`} readOnly />
                                <button type="button" onClick={() => handleRemoveOptionalOption(index)} className="remove-option-btn">X</button>
                            </div>
                        ))}

                        {newOptionalOptions.map((option, index) => (
                            <div key={index} className="new-option-fields">
                                <input type="text" value={option.option_name} onChange={(e) => handleNewOptionalOptionNameChange(e, index)} placeholder="Option Name" />
                                <input type="number" step="0.01" value={option.option_price} onChange={(e) => handleNewOptionalOptionPriceChange(e, index)} placeholder="Price" />
                                <button type="button" onClick={() => handleRemoveNewOptionalOption(index)} className="remove-option-btn">X</button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddNewOptionalOption}>Add Option</button>
                    </div>
                </div>
            </Modal>
        )
    );
};

export default AddItemModal;
